.staking-page-container{
    width: 100%;
    min-width: 340px;
}

.staking-page-main-text-container{
    flex-shrink: 10;
    flex-grow: 2;
    width: 50%;
    margin-left: 12px;
    margin-right: 12px;
    min-width: 357px;
}

.staking-page-stats-container{
    flex-grow: 4;
    margin-left: 12px;
    margin-right: 12px;
    max-width: 350px;
}

.staking-text-and-stats-flex{
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

.transparentCard h1{
    margin-right: 8px;
}

@media (max-width: 780px) {
    .staking-page-stats-container{
        max-width: 100%;
    }
    .staking-page-main-text-container{
        min-width: 100%;
    }
    .staking-text-and-stats-flex{
        margin-bottom: 20px;
    }
}